<template lang="pug">
  div
    vue-slick-carousel.latest-news__slider(
      v-if="getRandomNews.length"
      v-bind="settingsLatestNews"
    )
      .latest-news__slide(
        v-for="news in getRandomNews"
        :key="news.id"
      )
        div(@click="navigateToDetail(news.id, news.url)")
          .latest-news__img(v-if="news.image")
            img(
              :src="getImagePath(news.image)"
              :alt="news.title"
            )
          h4(v-if="news.title") {{ news.title }}
          p(v-if="news.description") {{ news.description }}
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'NewsCarousel',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      settingsLatestNews: {
        arrows: true,
        dots: true,
        draggable: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      newsList: state => state.directus.newsList
    }),
    getRandomNews () {
      if(this.newsList) {
        const shuffledNews = [...this.newsList]
        const randomList = () => Math.random() - 0.5
        shuffledNews.sort(randomList)
        return shuffledNews.slice(0, 3)
      }
      return []
    }
  },
  mounted () {
    this.fetchNews()
  },
  methods: {
    ...mapActions(['fetchNews']),
    navigateToDetail (newsId, newsUrl) {
      if (newsUrl !== this.$route.params.slug)
        this.$router.push({ name: 'NewsDetail', params: { slug: newsUrl } })
      else window.scrollTo(0, 0)
    },
    getImagePath (image) {
      return `${ process.env.VUE_APP_API }/assets/${ image }`
    }
  }
}
</script>
